export default [
    {
        type: "selection",
        width: 38,
        align: "center",
    },

    {
        title: 'Designation Name',
        key: 'NAME',
        minWidth: 80,
    }
]
